import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { applyToAllSlide, onFetchFontEditor, onFontFamily, onFontSize, onLayerTextAlign, onTextBold, onTextItalic, onTextLineHeight, onTextStrike, onTextUnderline, onTextUpper, removeFontBgColor, textLayerAdd, textLayerUpdate, updateFontBgColor } from '../../../../Actions/CampaignAction';
import ColorPicker from './ColorPicker';
import { fontNotSet } from '../../../../Global/FontFamilyData';

const Text = ({ campaign }) => {
    const dispatch = useDispatch();

    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")

    const [selectedLayer, setSelectedLayer] = useState(false)
    const [selectedLayerIndex, setSelectedLayerIndex] = useState(-1)
    const [fonts, setFonts] = useState([])
    const [slideAll, setSlideAll] = useState(false);

    const [layerText, setLayerText] = useState("")

    const [styleState, setStyleState] = useState({
        color: false,
        fontFamily: "",
        background: "",
        fontSize: 20,
        textAlign: "center",
        fontWeight: false,
        fontStyle: false,
        textTransform: "lowercase",
        textDecoration: "none",
        lineHeight: 1,
        opacity: 1,
    })

    const applyChanges = () => {
        if (slideAll === false) {
            setSlideAll(true);
            dispatch(applyToAllSlide(styleState));
        }
    }

    const onInputChange = (e, type) => {
        setSlideAll(false);
        if (type === 'size') {
            dispatch(onFontSize(e.target.value, selectedSlideIndex, selectedLayerIndex));
        } else if (type === 'font_family') {
            dispatch(onFontFamily(e.target.value, selectedSlideIndex, selectedLayerIndex));
        }

    }

    // Getting hex color RGB number 


    const onTextAlign = (align) => {
        setSlideAll(false);
        dispatch(onLayerTextAlign(align, selectedSlideIndex, selectedLayerIndex, selectedSlide.type));
    }

    const onChangeLineHeight = (e) => {
        setSlideAll(false);
        dispatch(onTextLineHeight(+e.target.value, selectedSlideIndex, selectedLayerIndex, selectedSlide.type))
    }

    const onTextStyle = (type) => {
        setSlideAll(false);
        if (type === 'bold') {
            if (styleState.fontWeight === false) {
                dispatch(onTextBold("bold", selectedSlideIndex, selectedLayerIndex));
            } else {
                dispatch(onTextBold(false, selectedSlideIndex, selectedLayerIndex));
            }
        } else if (type === 'italic') {
            if (styleState.fontStyle === false) {
                dispatch(onTextItalic("italic", selectedSlideIndex, selectedLayerIndex));
            } else {
                dispatch(onTextItalic(false, selectedSlideIndex, selectedLayerIndex));
            }
        } else if (type === 'uppercase') {
            if (styleState.textTransform === "uppercase") {
                dispatch(onTextUpper("none", selectedSlideIndex, selectedLayerIndex));
            } else {
                dispatch(onTextUpper("uppercase", selectedSlideIndex, selectedLayerIndex));
            }
        } else if (type === 'lowercase') {
            if (styleState.textTransform === "lowercase") {
                dispatch(onTextUpper("none", selectedSlideIndex, selectedLayerIndex));
            } else {
                dispatch(onTextUpper("lowercase", selectedSlideIndex, selectedLayerIndex));
            }
        } else if (type === 'underline') {
            if (styleState.textDecoration === "underline") {
                dispatch(onTextUnderline("none", selectedSlideIndex, selectedLayerIndex));
            } else {
                dispatch(onTextUnderline("underline", selectedSlideIndex, selectedLayerIndex));
            }
        } else if (type === 'strike') {
            if (styleState.textDecoration === "line-through") {
                dispatch(onTextStrike("none", selectedSlideIndex, selectedLayerIndex));
            } else {
                dispatch(onTextStrike("line-through", selectedSlideIndex, selectedLayerIndex));
            }
        }
    }


    useEffect(() => {
        if (campaign.slides) {
            let val = campaign.slides[selectedSlideIndex].layers.find(({ isSelected }) => isSelected === true)
            let valIndex = campaign.slides[selectedSlideIndex].layers.findIndex(({ isSelected }) => isSelected === true)
            setSelectedLayerIndex(valIndex)
            setSelectedLayer(val)
        }
    }, [campaign.slides, selectedSlideIndex])



    useEffect(() => {
        if (selectedLayer !== false && selectedLayer !== undefined) {
            if (selectedLayer.isSelected === true) {
                setStyleState(selectedLayer.style)

            }
        }
        if (selectedLayer) {
            if (selectedLayer.type === "text") {
                setLayerText(selectedLayer.src)
            }
        }
    }, [selectedLayer])


    useEffect(() => {
        dispatch(onFetchFontEditor(setFonts, fontNotSet))
    }, [])


    const onLayerTextChange = (text) => {
        setLayerText(text)
        dispatch(textLayerUpdate(text, selectedSlideIndex, selectedLayerIndex))
    }
    const addTextLayer = () => {
        dispatch(textLayerAdd(selectedSlideIndex))
    }


    return (
        <div className="tabInner">
            <div className="textarea-block-wrap mb-4">
                <div className="textarea-block-head d-flex justify-content-between align-items-center">
                    <h2>Text</h2>
                    <button
                        className='textarea-btn'
                        onClick={addTextLayer}
                    >
                        Add New Text Layer
                    </button>
                </div>
                {selectedLayer ?
                    selectedLayer.type === "text" ?
                        <div className="textarea-wrapper pt-3 ">
                            <div className="mb-3">
                                <textarea
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                    rows="3"
                                    value={layerText}
                                    onChange={(e) => onLayerTextChange(e.target.value)}
                                />
                            </div>
                        </div> : "" : ""}

            </div>
            {selectedLayer ?
                selectedLayer.type === "text" || selectedLayer.type === "shape" ?
                    <div className="txt-format">
                        {selectedLayer.type === "text" ?
                            <>
                                <h2>Font Style</h2>
                                <div className="fontStyle">
                                    <div className="fontStyle-block">
                                        <div className="row">
                                            <div className="col-8">
                                                <select
                                                    id="sel-font"
                                                    name="font_family"
                                                    className='cursor-pointer text-hover-effect'
                                                    onChange={(e) => onInputChange(e, 'font_family')}
                                                    value={styleState.fontFamily}
                                                >
                                                    <option value="" selected>Select Font</option>
                                                    {
                                                        fonts.length > 0 ?
                                                            fonts.map((font, index) => {
                                                                return (
                                                                    <option style={{ fontFamily: font }}
                                                                        value={font} key={index}>
                                                                        {font}
                                                                    </option>
                                                                )
                                                            })
                                                            : ''
                                                    }

                                                </select>
                                            </div>
                                            <div className="col-4">
                                                <input
                                                    id="f-size"
                                                    type="number"
                                                    className="form-control edit-input-font-size text-hover-effect"
                                                    name="f-size"
                                                    min="10"
                                                    value={+styleState.fontSize}
                                                    onChange={(e) => onInputChange(e, 'size')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fontStyle-block">
                                        <div className="row">
                                            <div className="col-2">
                                                <div
                                                    className={`${styleState.fontWeight === "bold" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect `}
                                                    onClick={() => onTextStyle('bold')}
                                                    data-toggle="tooltip"
                                                    title="Text Bold"
                                                ><span className="font-weight-bold">T</span>
                                                </div>

                                            </div>
                                            <div className="col-2">

                                                <div
                                                    className={`${styleState.fontStyle === "italic" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                                                    onClick={() => onTextStyle('italic')}
                                                    data-toggle="tooltip"
                                                    title="Text Italic"
                                                ><span className="font-italic">T</span>
                                                </div>

                                            </div>
                                            <div className="col-2">

                                                <div
                                                    className={`${styleState.textTransform === "uppercase" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                                                    onClick={() => onTextStyle('uppercase')}
                                                    data-toggle="tooltip"
                                                    title="Text Uppercase"
                                                ><span>TT</span></div>
                                            </div>

                                            <div className="col-2">

                                                <div
                                                    className={`${styleState.textTransform === "lowercase" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                                                    onClick={() => onTextStyle('lowercase')}
                                                    data-toggle="tooltip"
                                                    title="Text Lowercase"
                                                ><span>tt</span></div>
                                            </div>
                                            <div className="col-2">
                                                <div
                                                    className={`${styleState.textDecoration === "underline" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                                                    onClick={() => onTextStyle('underline')}
                                                    data-toggle="tooltip"
                                                    title="Text Underline"
                                                ><span className="text-decoration-underline">T</span></div>
                                            </div>
                                            <div className="col-2">
                                                <div
                                                    className={`${styleState.textDecoration === "line-through" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                                                    onClick={() => onTextStyle('strike')}
                                                    data-toggle="tooltip"
                                                    title="Text Strike"
                                                ><span className="text-decoration-line-through">T</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fontStyle-block">
                                        <div className="row">
                                            <div className="col-2">

                                                <div
                                                    className={`${styleState.textAlign === 'left' ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                                                    onClick={() => onTextAlign('left')}
                                                    data-toggle="tooltip"
                                                    title="Text Left"
                                                ><i className="fa fa-align-left" aria-hidden="true"></i></div>
                                            </div>

                                            <div className="col-2">

                                                <div
                                                    className={`${styleState.textAlign === 'center' ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                                                    onClick={() => onTextAlign('center')}
                                                    data-toggle="tooltip"
                                                    title="Text Center"
                                                ><i className="fa fa-align-center" aria-hidden="true"></i></div>
                                            </div>

                                            <div className="col-2">

                                                <div
                                                    className={`${styleState.textAlign === 'right' ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                                                    onClick={() => onTextAlign('right')}
                                                    data-toggle="tooltip"
                                                    title="Text Right"
                                                ><i className="fa fa-align-right" aria-hidden="true"></i></div>
                                            </div>

                                            <div className="col-2">

                                                <div
                                                    className={`${styleState.textAlign === 'justify' ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                                                    onClick={() => onTextAlign('justify')}
                                                    data-toggle="tooltip"
                                                    title="Text Justify"
                                                ><i className="fa fa-align-justify" aria-hidden="true"></i></div>
                                            </div>

                                            <div className="col-4">

                                                <div
                                                    className={'form-format'}
                                                    data-toggle="tooltip"
                                                    title="Text Line Height"
                                                >
                                                    <input
                                                        id="t-lineheight"
                                                        className="edit-input-lineheight text-hover-effect"
                                                        type="number"
                                                        name="Lineheight"
                                                        value={styleState.lineHeight}
                                                        min="1"
                                                        step="0.1"
                                                        onChange={(e) => onChangeLineHeight(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="fontStyle-block-wrap">
                                    <h2>Font Color</h2>
                                    <ColorPicker
                                        colorCode={styleState.color}
                                        setStyleState={setStyleState}
                                        selectedSlide={selectedSlide}
                                        slideIndex={selectedSlideIndex}
                                        type="textColor"
                                        setSlideAll={setSlideAll}
                                    />
                                </div>
                            </> : ""}




                        <div className="fontStyle-block-wrap">
                            <h2>Background</h2>
                            <ColorPicker
                                colorCode={styleState.background}
                                setStyleState={setStyleState}
                                selectedSlide={selectedSlide}
                                slideIndex={selectedSlideIndex}
                                type="bgColor"
                                setSlideAll={setSlideAll}
                            />
                        </div>
                        {selectedLayer.type === "text" ?
                            <div className="fontStyle-block-wrap">
                                <div className="itemDisp">
                                    <div className="itemDisp-left">
                                        Apply to all slides
                                    </div>
                                    <div className="itemDisp-right">
                                        <div className="switch-single">
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={slideAll}
                                                    disabled={slideAll}
                                                    onChange={(e) => applyChanges()}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div> : ""}

                    </div> : "" : ""}
        </div>
    );
};

export default Text;
