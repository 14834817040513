import React from 'react'
import Navbar from "../Navbar";
import Footer from "../Footer";
import SettingMenu from './SettingMenu'
import SettingContent from './SettingContent'
import TitleBar from "../TitleBar";
import { useSelector } from 'react-redux';
import { appName } from '../../Global/Global';
import { Helmet } from 'react-helmet';

const Settings = () => {
  const brandName = useSelector(state => state.rebrand.data);

  return (
    <>
      <Helmet >
        <title>{brandName !== false ? brandName.name : appName} | Settings</title>
      </Helmet>
      <Navbar />

      <section className="siteWrap">
        <div className="tabCont">
          <div className="row">
            <div className="col-lg-3 tabLeft">
              <div className="tabCont-left">
                <SettingMenu />
              </div>
            </div>

            <SettingContent />


          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default Settings