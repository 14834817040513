import React from "react";
import Navbar from "../Navbar";
import TrainingTitles from "./TrainingTitles";
import TrainingContents from "./TrainingContents";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { appName } from "../../Global/Global";
import { Helmet } from "react-helmet";

const Training = () => {
    let location = useLocation();
    const supportData = useSelector(state => state.support.articles);
    const articleId = queryString.parse(location.search).id;
    const brandName = useSelector(state => state.rebrand.data);

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Training</title>
            </Helmet>
            <Navbar />

            <section className="siteWrap">
                <div className="tabCont">
                    <div className="row">


                        {supportData.length > 0 ?
                            <>
                                <TrainingTitles location={location} id={articleId} supportData={supportData} name={false} />
                                <TrainingContents location={location} />
                            </> :
                            <div className="spinner" style={{ width: '100%', height: "900px" }}>
                                <div className="spinner_tag" style={{ position: "absolute", top: '50%', left: '50%', margin: "0 auto" }}>
                                    <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#e0742d", fontSize: "30px" }} />
                                </div>
                            </div>

                        }
                    </div>

                </div>
            </section>

            <Footer />
        </>
    )
}

export default Training;