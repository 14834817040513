import React from "react";
import Navbar from "../Navbar";
import HelpContent from "./HelpContent";
import HelpVideos from "./HelpVideos";
import Footer from "../Footer";
import CustomerSupport from "./CustomerSupport";
import TitleBar from "../TitleBar";
import { useSelector } from "react-redux";
import { appName } from "../../Global/Global";
import { Helmet } from "react-helmet";

const HelpSupport = () => {
    const brandName = useSelector(state => state.rebrand.data);


    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Help & Support</title>
            </Helmet>
            <Navbar />

            <HelpContent />
            <HelpVideos />
            <CustomerSupport />

            <Footer />
        </>
    )
}

export default HelpSupport;