import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import iconWrite from "../../images/icon-write.svg";
import iconRight from "../../images/icon-arrow.svg";
import modalCloseIcon from "../../images/modal-close.png";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { appName } from "../../Global/Global";
import { BiConversation } from "react-icons/bi";
import ProgressBar from 'react-bootstrap/ProgressBar';
// import { onFetchProgress, onFetchStaffDataForDashboard } from "../../Actions/StaffAction";

import video from '../../video/video.mp4'

const DashboardHead = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const rebrandData = useSelector((state) => state.rebrand.data);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [progress, setProgress] = useState(0)
  const [state, setState] = useState({
    hiredStaff: 0,
    totalStaff: 0,
  })

  return (
    <>
      <section className="hero-banner">
        <div className="hero-video">
          <video autoPlay loop muted style={{ width: "100%" }} src={video} />
        </div>
        <div className="hero-banner-txt">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="hero-banner-left">
                  <h2>WELCOME TO</h2>
                  <h3 className="text-uppercase">
                    {rebrandData?.name ? rebrandData.name : appName}
                  </h3>
                  <p>
                    Streamline the process of producing captivating video ads in just minutes. Say goodbye to time-consuming scriptwriting and storyboarding - {rebrandData ? rebrandData.name : appName} automatically generates customized scripts and storyboards based on your business name and details. Get ready to captivate your audience and boost your marketing efforts with {rebrandData ? rebrandData.name : appName}.
                  </p>
                  <button onClick={handleShow} className="demoLink">
                    <i className="fa fa-play" aria-hidden="true"></i> Watch Demo
                    Video
                  </button>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="hero-banner-right">
                  {+auth.user.is_client_account !== 1 ?
                    <Link to="/create-video" className="bannerLink">
                      <div className="bannerLink-left">
                        {/* <span>
                            <div className="numberCount">{state.hiredStaff}/{state.totalStaff}</div>
                          </span> */}
                        <img src={require('../../images/icon-write.svg').default} />
                        Create Ads Video
                      </div>
                      <div className="bannerLink-right">
                        <img alt="" src={iconRight} />
                      </div>
                    </Link> : ""}
                  <Link to="/projects" className="bannerLink">
                    <div className="bannerLink-left">
                      <img src={require('../../images/icon-upload.svg').default} />
                      View Ads Video
                    </div>
                    <div className="bannerLink-right">
                      <img alt="" src={iconRight} />
                    </div>
                  </Link>
                  {+auth.user.is_client_account !== 1 ?
                    <Link to="/integration" className="bannerLink">
                      <div className="bannerLink-left">
                        <img src={require('../../images/icon-mic.svg').default} />
                        Integrations

                      </div>
                      <div className="bannerLink-right">
                        <img alt="" src={iconRight} />
                      </div>
                    </Link> : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >

      <Modal className="VideoModal" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div onClick={handleClose} className="vidClose">
            <img alt="" src={modalCloseIcon} />
          </div>
          <div className="modalVidWrap">
            <div className="modalVid">
              <iframe
                src="https://player.vimeo.com/video/846360572?h=28c219f82c?loop=false&amp;autoplay=true&amp;muted=false&amp;gesture=media&amp;playsinline=true&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=false;"
                allowFullScreen="true"
                allow="autoplay,fullscreen,picture-in-picture"
                title="Player for FastPaid Intro"
                data-ready="true"
                tabIndex="-1"
              ></iframe>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DashboardHead;
