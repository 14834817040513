import { combineReducers } from "redux";
import { AlertReducer } from "./AlertReducer";
import { AuthReducer } from "./AuthReducer";
import { RebrandReducer } from "./RebrandReducer";
import { SupportReducer } from "./SupportReducer";
import { SocialReducer } from "./SocialReducer";
import { CampaignReducer } from "./CampaignReducer";
import { WhiteLabelData } from "./WhiteLabelData";

const appReducer = combineReducers({
    alert: AlertReducer,
    auth: AuthReducer,
    support: SupportReducer,
    rebrand: RebrandReducer,
    social: SocialReducer,
    campaign: CampaignReducer,
    wl: WhiteLabelData,
})

const rootReducers = (state, action) => {
    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = {
            ...state,
            alert: undefined,
            auth: undefined,
            support: undefined,
            rebrand: +state.auth?.user?.isWL === 1 ? state ? state.rebrand : undefined : undefined,
            social: undefined,
            campaign: undefined,
            wl: undefined,
        };
    }
    return appReducer(state, action)
}

export default rootReducers;