import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginFooter from "./LoginFooter";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../Alert";
import logo from '../../images/ADSREEL-LOGO-WHITE.png';
import { loginUser } from "../../Actions/AuthAction";
import TitleBar from "../TitleBar";
import { appName } from "../../Global/Global";
import { Helmet } from "react-helmet";


const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand.data);
    const brandName = useSelector(state => state.rebrand.data);

    useEffect(() => {
        if (auth.isAuthenticated) {
            navigate('/dashboard');
        }
    }, [auth]);

    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState({
        email: '',
        password: ''
    })

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch(loginUser(user, setLoader));
    }

    return (
        <>
            <Helmet >
                <title>{brandName !== false ? brandName.name : appName} | Login </title>
            </Helmet>

            <Alert />
            <header className="loginHeader">
                <div className="container">
                    <div className="loginLogo">
                        <img
                            className="img-fluid"
                            src={rebrandData?.logo || logo}
                            alt="Logo"
                            height="auto"
                        />
                    </div>
                </div>
            </header>

            <div className="login-container d-flex align-items-center">
                <div className="container d-flex justify-content-center align-items-center flex-column flex-lg-row gap-5">
                    {/* <div className="row full-height-row align-items-center"> */}
                    {/* <div className="col-lg-6"> */}
                    <div className="login-container-left w-100">
                        <h2><span>Welcome To </span>{rebrandData?.name ? rebrandData?.name : appName}!</h2>                        <p>Create Professional Video Ads Without Scriptwriting or <br /> Storyboarding Expertise!</p>
                    </div>
                    {/* </div> */}
                    {/* <div className="col-lg-6"> */}
                    <div className="login-container-right w-100 px-2 px-sm-5 px-lg-0">
                        <div className="login-container-right-main">
                            <h2><span>Login</span> to Your Account Now</h2>
                            <form method="post" onSubmit={(e) => onFormSubmit(e)}>
                                <div className="form-group">
                                    <label>Email Address</label>
                                    <input type="email" name="email" className="form-control"
                                        placeholder="Email Address" required
                                        onChange={(e) => onInputChange(e)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        name="password"
                                        className="form-control"
                                        placeholder="************" required
                                        onChange={(e) => onInputChange(e)}
                                        maxLength={32}
                                    />
                                </div>

                                <div className="form-group">
                                    <button type="submit" className="btn btn-block btn-primary themeBtn" disabled={loader}>
                                        {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Sign in {appName}
                                    </button>
                                </div>

                                <div className="form-group">
                                    <p className="forgotLink">Forgot your account details? <Link to="/forget-password" className="text-center "> Click here</Link></p>
                                </div>

                            </form>
                        </div>
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                </div>
            </div>


            <LoginFooter />

        </>
    )
}

export default Login;

