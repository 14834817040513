import React, { useState } from "react";
import LoginFooter from "./LoginFooter";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Alert from "../Alert";
import logo from '../../images/ADSREEL-LOGO-WHITE.png';
import TitleBar from "../TitleBar";
import { forgetPassword } from "../../Actions/AuthAction";
import { appName } from "../../Global/Global";
import { useSelector } from "react-redux";

const ForgetPassword = () => {

    const dispatch = useDispatch();
    const rebrandData = useSelector(state => state.rebrand.data);

    const [loader, setLoader] = useState(false);
    const [emailDetails, setEmailDetails] = useState({
        email: ''
    })

    const onInputChange = (e) => {
        setEmailDetails({ ...emailDetails, email: e.target.value });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch(forgetPassword(emailDetails, setLoader));
    }

    return (
        <>

            <title>{rebrandData !== false ? rebrandData.name : appName} | Forget Password </title>
            <Alert />

            <header className="loginHeader">
                <div className="container">
                    <div className="loginLogo">
                        <img
                            className="img-fluid"
                            src={rebrandData?.logo || logo}
                            alt="Logo"
                            height="auto"
                        />
                    </div>
                </div>
            </header>

            <div className="login-container d-flex align-items-cente">
                <div className="container d-flex justify-content-center align-items-center flex-column flex-lg-row gap-5">
                    {/* <div className="row full-height-row align-items-center"> */}
                    {/* <div className="col-lg-6"> */}
                    <div className="login-container-left w-100">
                        <h2>Welcome <span>{rebrandData?.name ? rebrandData?.name : appName}!</span></h2>
                        <p>Create Professional Video Ads Without Scriptwriting or <br /> Storyboarding Expertise!</p>
                    </div>
                    {/* </div> */}
                    {/* <div className="col-lg-6"> */}
                    <div className="login-container-right w-100 px-2 px-sm-5 px-lg-0">
                        <div className="login-container-right-main">
                            <h2><span>Enter </span> Your Email Address</h2>
                            <form method="post" onSubmit={(e) => onFormSubmit(e)}>
                                <div className="form-group">
                                    <label>Email Address</label>
                                    <input type="email" className="form-control" placeholder="Enter Email"
                                        required
                                        onChange={(e) => onInputChange(e)}
                                    />
                                </div>

                                <div className="form-group">
                                    <button type="submit" className="btn btn-block btn-primary themeBtn" disabled={loader}>
                                        {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Submit
                                    </button>
                                </div>

                                <div className="form-group">
                                    <p className="forgotLink"> <Link to="/login" className="text-center "> Remember Login ?</Link></p>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
            {/* </div> */}

            <LoginFooter />

        </>
    )
}

export default ForgetPassword;