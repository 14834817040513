import React from "react";
import Footer from "../Footer";
import DashboardHead from "./DashboardHead";
import DashboardContent from "./DashboardContent";
import DashboardAbout from "./DashboardAbout";
import Webinar from "./Webinar";
import TitleBar from "../TitleBar";
import Navbar from "../Navbar";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { loadUser } from "../../Actions/AuthAction";
import { useSelector } from "react-redux";
import { appName } from "../../Global/Global";
import { Helmet } from "react-helmet";


const Dashboard = () => {
    const brandName = useSelector(state => state.rebrand.data);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(loadUser())
    }, [])

    return (
        <>
            <Helmet >
                <title>{brandName !== false ? brandName.name : appName} | Dashboard</title>
            </Helmet>
            <Navbar />
            <section className="siteWrap">
                <DashboardHead />
                {/* <DashboardContent /> */}
                <DashboardAbout />
            </section>
            <Webinar />
            <Footer />
        </>
    )
}

export default Dashboard;