import React, { useEffect, useState } from "react";
import ConnectReelapps from "./ConnectReelapps";
import { useDispatch, useSelector } from "react-redux";
import ConnectFacebook from "./ConnectFacebook";
import ConnectGoogle from "./ConnectGoogle";
import facebookIcon from "../../images/logo-facebook.svg"
import youtubeIcon from "../../images/YT-DEFAULT.png"
import syvidIcon from "../../images/syvid.svg"
import scriptIcon from "../../images/scriptreel.svg"
import trimIcon from "../../images/logo-trim.svg"
import liveIcon from "../../images/livereel.svg"
import sonorityIcon from "../../images/sonority.svg"
import videoIcon from "../../images/videoreel.svg"
import Footer from "../Footer";
import Navbar from "../Navbar";
import TitleBar from "../TitleBar";
import { fetchSocialAccounts } from "../../Actions/SocialAction";
import SocialConnected from "./SocialConnected";
import { appName } from "../../Global/Global";
import { Helmet } from "react-helmet";

const Integration = () => {
    const dispatch = useDispatch()
    const social = useSelector(state => state.social.data)
    const brandName = useSelector(state => state.rebrand.data);

    const fetchIntegration = () => {
        dispatch(fetchSocialAccounts())
    }

    useEffect(() => {
        fetchIntegration()
    }, [])


    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Integration</title>
            </Helmet>
            <Navbar />

            <div className="siteWrap">
                <div className="projectTitle-wrap">
                    <div className="container">
                        <div className="projectTitle">
                            <div className="projectTitle-left">
                                <h2>Connected</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <section className="connection">

                        <div className="container">
                            <div className="siteWrap-in siteWrapN">

                                <div className="integr-wrap pt-0 px-3">
                                    <div className="row">
                                        {social.facebook.length > 0 ?
                                            social.facebook.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <SocialConnected
                                                            type="facebook"
                                                            icon={facebookIcon}
                                                            data={curElem}
                                                            fetchIntegration={fetchIntegration}
                                                        />
                                                    </React.Fragment>
                                                )

                                            })
                                            : ""}
                                        {social.youtube.length > 0 ?
                                            social.youtube.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <SocialConnected
                                                            type="youtube"
                                                            icon={youtubeIcon}
                                                            data={curElem}
                                                            fetchIntegration={fetchIntegration}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            : ""}

                                        {social.syvid.length > 0 ?
                                            social.syvid.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <SocialConnected
                                                            type="syvid"
                                                            icon={syvidIcon}
                                                            data={curElem}
                                                            fetchIntegration={fetchIntegration}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            : ""}

                                        {social.scriptreel.length > 0 ?
                                            social.scriptreel.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <SocialConnected
                                                            type="scriptreel"
                                                            icon={scriptIcon}
                                                            data={curElem}
                                                            fetchIntegration={fetchIntegration}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            : ""}
                                        {social.trimreel.length > 0 ?
                                            social.trimreel.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <SocialConnected
                                                            type="trimreel"
                                                            icon={trimIcon}
                                                            data={curElem}
                                                            fetchIntegration={fetchIntegration}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            : ""}
                                        {social.livereel.length > 0 ?
                                            social.livereel.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <SocialConnected
                                                            type="livereel"
                                                            icon={liveIcon}
                                                            data={curElem}
                                                            fetchIntegration={fetchIntegration}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            : ""}
                                        {social.sonority.length > 0 ?
                                            social.sonority.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <SocialConnected
                                                            type="sonority"
                                                            icon={sonorityIcon}
                                                            data={curElem}
                                                            fetchIntegration={fetchIntegration}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            : ""}
                                        {social.videoreel.length > 0 ?
                                            social.videoreel.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <SocialConnected
                                                            type="videoveel"
                                                            icon={videoIcon}
                                                            data={curElem}
                                                            fetchIntegration={fetchIntegration}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            : ""}

                                        <div className="text-center">
                                            {
                                                social.facebook.length === 0 &&
                                                    social.youtube.length === 0 &&
                                                    social.scriptreel.length === 0 &&
                                                    social.livereel.length === 0 &&
                                                    social.trimreel.length === 0 &&
                                                    social.syvid.length === 0 &&
                                                    social.sonority.length === 0 &&
                                                    social.videoreel.length === 0
                                                    ?
                                                    "You have not connected any account yet"
                                                    : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluidn">

                            <div className="projectTitle-wrap">
                                <div className="container">
                                    <div className="projectTitle">
                                        <div className="projectTitle-left">
                                            <h2>Available</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid mb-5">
                            <div className="integr-wrap pt-0 px-3">
                                <div className="row">
                                    <div className="col-sm-6 col-lg-4">
                                        <ConnectFacebook
                                            fetchIntegration={fetchIntegration}
                                        />
                                    </div>
                                    <div className="col-sm-6 col-lg-4">
                                        <ConnectGoogle
                                            fetchIntegration={fetchIntegration}
                                        />
                                    </div>

                                    <div className="col-sm-6 col-lg-4">
                                        <ConnectReelapps
                                            fetchIntegration={fetchIntegration}
                                            icon={syvidIcon}
                                            name="Syvid"
                                            type="syvid"
                                        />
                                    </div>
                                    <div className="col-sm-6 col-lg-4">
                                        <ConnectReelapps
                                            fetchIntegration={fetchIntegration}
                                            icon={scriptIcon}
                                            name="ScriptReel"
                                            type="scriptreel"
                                        />
                                    </div>
                                    <div className="col-sm-6 col-lg-4">
                                        <ConnectReelapps
                                            fetchIntegration={fetchIntegration}
                                            icon={trimIcon}
                                            name="TrimReel"
                                            type="trimreel"
                                        />
                                    </div>
                                    <div className="col-sm-6 col-lg-4">
                                        <ConnectReelapps
                                            fetchIntegration={fetchIntegration}
                                            icon={liveIcon}
                                            name="LiveReel"
                                            type="livereel"
                                        />
                                    </div>
                                    <div className="col-sm-6 col-lg-4">
                                        <ConnectReelapps
                                            fetchIntegration={fetchIntegration}
                                            icon={sonorityIcon}
                                            name="Sonority"
                                            type="sonority"
                                        />
                                    </div>
                                    <div className="col-sm-6 col-lg-4">
                                        <ConnectReelapps
                                            fetchIntegration={fetchIntegration}
                                            icon={videoIcon}
                                            name="VideoReel"
                                            type="videoreel"
                                        />
                                    </div>

                                </div>

                            </div>

                        </div>


                    </section>

                </div>

            </div >
            <Footer />
        </>
    )
}

export default Integration;