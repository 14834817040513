import React, { useState } from 'react'
import { BsUpload } from 'react-icons/bs'
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { uploadBusinessLogo, uploadMultipleMedia } from '../../Actions/CampaignAction';
import { AiFillCloseCircle } from "react-icons/ai"

const CustomForm = ({ state, setState, fonts, handleChange }) => {

    const dispatch = useDispatch();
    const [loader, setLoader] = useState({
        imageLoader: false,
        videoLoader: false,
        logoLoader: false,
    })


    const handleRemove = (index, type) => {
        if (type === "image") {
            let data = [...state.images]
            data.splice(index, 1)
            setState({ ...state, images: data })
        } else {
            let data = [...state.videos]
            data.splice(index, 1)
            setState({ ...state, videos: data })
        }
    }

    const onUploadFile = (e) => {
        if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg') {
            if (e.target.files[0].size < 5000000) {
                const formData = new FormData()
                formData.append('upload_type', "images");
                formData.append('file', e.target.files[0]);
                setLoader({
                    ...loader,
                    logoLoader: true
                })
                dispatch(uploadBusinessLogo(formData, state, setState, loader, setLoader))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Max allowed size is 5MB!',
                    confirmButtonColor: "#e0712c"
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have selected invalid file type!',
                confirmButtonColor: "#e0712c"
            })
        }
    }

    const onUploadMultipleFile = (e, type) => {
        const formData = new FormData();
        let validTypes = [];
        let maxSize = 0;
        let isValid = true;
        if (type === "images") {
            validTypes = ['image/png', 'image/jpg', 'image/jpeg'];
            maxSize = 5000000;
        } else {
            validTypes = ['video/mp4'];
            maxSize = 20000000;
        }

        Array.from(e.target.files).forEach((file, index) => {
            if (!validTypes.includes(file.type)) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected an invalid file type!',
                    confirmButtonColor: '#e0712c'
                });
                isValid = false;
                return;
            }

            if (file.size > maxSize) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `Max allowed size is ${type === "images" ? 5 : 20}MB!`,
                    confirmButtonColor: '#e0712c'
                });
                isValid = false;
                return;
            }

            formData.append('files[]', file);

        });

        if (e.target.files.length > 0) {
            formData.append('type', type);
            if (type === "images") {
                setLoader({ ...loader, imageLoader: true });
            } else {
                setLoader({ ...loader, videoLoader: true });
            }
            dispatch(uploadMultipleMedia(formData, type, state, setState, loader, setLoader));
        }
    }


    return (
        <section className="custom_form">
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group form-group-alt">
                        <label htmlFor="form-text text-muted">Business Name</label>
                        <input
                            type="text"
                            name="businessName"
                            className="form-control"
                            placeholder="Enter Business Name"
                            value={state.businessName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group form-group-alt">
                        <label htmlFor="form-text text-muted">Business Address </label>
                        <input
                            type="text"
                            name="businessAddress"
                            className="form-control"
                            placeholder="Enter Business Address"
                            value={state.businessAddress}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group form-group-alt">
                        <label htmlFor="form-text text-muted">Business Phone</label>
                        <input
                            type="text"
                            name="businessPhone"
                            className="form-control"
                            placeholder="Enter Business Phone Number"
                            value={state.businessPhone}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group form-group-alt">
                        <label htmlFor="form-text text-muted">Business Font </label>
                        <select
                            className="form-select form-control"
                            aria-label="Default select example"
                            name="businessFont"
                            onChange={handleChange}
                            value={state.businessFont}
                            required
                        >
                            <option value="">Select Font Family</option>
                            {
                                fonts.length > 0 ?
                                    fonts.map((font, index) => {
                                        return (
                                            <option style={{ fontFamily: font }}
                                                value={font} key={index}>
                                                {font}
                                            </option>
                                        )
                                    })
                                    : ''
                            }
                        </select>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group form-group-alt">
                        <label htmlFor="form-text text-muted">About Business  </label>
                        <textarea
                            type="text"
                            name="aboutBusiness"
                            className="form-control"
                            placeholder="Enter About Business"
                            value={state.aboutBusiness}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="row align-items-center" style={{ marginTop: "30px", }}>
                        <div className="col-lg-6">
                            <div className="form-group form-group-alt mt-0">

                                <div className="style-upload-box">
                                    <input
                                        className="custom-file-input"
                                        type="file"
                                        name='images'
                                        multiple
                                        accept="image/*"
                                        onChange={(e) => onUploadMultipleFile(e, "images")}
                                    />
                                    <div>
                                        <BsUpload />
                                        <h6>Upload your Image</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 text-center">
                            <div className='scrape-business-img-warp'>
                                {
                                    state.images.length > 0 ?
                                        state.images.map((url, index) => {
                                            return (
                                                <div key={index} className="scrape-business-img">
                                                    <div style={{ position: "relative", border: "3px solid #fff", borderRadius: 5, width: "100%", height: "100%" }}>
                                                        <img src={url} alt='' style={{ borderRadius: 5 }} />
                                                        <AiFillCloseCircle className="cursor-pointer" style={{ color: "#e0712c", position: 'absolute', right: 2, top: 2 }} onClick={() => handleRemove(index, "image")} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : ""
                                }
                                {loader.imageLoader ?
                                    <div style={{ marginLeft: "10px", display: "flex", height: "100%", alignItems: "center", justifyContent: state.images.length > 0 ? "" : "center", width: state.images.length > 0 ? "" : "100%" }}>
                                        <i className="fa fa-spinner fa-spin" style={{ fontSize: "25px", color: "#e0712c" }} />
                                    </div>
                                    : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="row align-items-center" style={{ marginTop: "30px", }}>
                        <div className="col-lg-6">
                            <div className="form-group form-group-alt m-0">

                                <div className="style-upload-box">
                                    <input
                                        className="custom-file-input"
                                        type="file"
                                        name='videos'
                                        multiple
                                        accept="video/*"
                                        onChange={(e) => onUploadMultipleFile(e, "videos")}
                                    />
                                    <div>
                                        <BsUpload />
                                        <h6>Upload your video</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 text-center">

                            <div className='scrape-business-video-warp'>
                                {
                                    state.videos.length > 0 ?
                                        state.videos.map((url, index) => {
                                            return (
                                                <div key={index} className="scrape-business-video">
                                                    <div style={{ position: "relative", border: "3px solid #fff", borderRadius: 5, width: "100%", height: "100%" }}>
                                                        <video src={url} />
                                                        <AiFillCloseCircle className="cursor-pointer" style={{ color: "#e0712c", position: 'absolute', right: 2, top: 2 }} onClick={() => handleRemove(index, "video")} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : ""
                                }
                                {loader.videoLoader ?
                                    <div style={{ marginLeft: "10px", display: "flex", height: "100%", alignItems: "center", justifyContent: state.videos.length > 0 ? "" : "center", width: state.videos.length > 0 ? "" : "100%" }}>
                                        <i className="fa fa-spinner fa-spin" style={{ fontSize: "25px", color: "#e0712c" }} />
                                    </div>
                                    : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="row align-items-center" style={{ marginTop: "30px", }}>
                        <div className="col-lg-6">
                            <div className="form-group form-group-alt m-0">
                                <div className="style-upload-box">
                                    <input
                                        className="custom-file-input"
                                        type="file"
                                        name='logo'
                                        accept="image/*"
                                        onChange={(e) => onUploadFile(e)}
                                    />
                                    <div>
                                        <BsUpload />
                                        <h6>Upload your Logo</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 text-center">

                            <div className='scrape-business-img-warp'>

                                {loader.logoLoader ?
                                    <div style={{ marginLeft: "10px", display: "flex", height: "100%", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                        <i className="fa fa-spinner fa-spin" style={{ fontSize: "25px", color: "#e0712c" }} />
                                    </div>
                                    :
                                    state.logo !== "" ?
                                        <div className="scrape-business-logo">
                                            <img src={state.logo} style={{ maxWidth: "100%", maxHeight: "100%", padding: "10px", borderRadius: '8px' }} alt='' />
                                        </div>
                                        : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default CustomForm
